<template>
  <svg viewBox="0 0 22 25" :width="size">
    <switch>
      <g>
        <path
          d="M2.1 15.2c-.3 0-.6-.3-.6-.6v-4.5c0-.3.3-.6.6-.6s.6.3.6.6v4.5c0 .3-.3.6-.6.6z"
        />
        <path
          d="M2.1 15.7c-.6 0-1-.5-1-1v-4.5c0-.6.5-1 1-1s1 .5 1 1v4.5c0 .5-.4 1-1 1zm0-5.7-.1.1v4.5c0 .1.2.1.2 0v-4.5s0-.1-.1-.1z"
        />
        <path
          d="M18.7 24.3H4.1c-1.4 0-2.5-1.1-2.5-2.5v-1c0-.3.3-.6.6-.6s.6.3.6.6v1c0 .8.6 1.4 1.4 1.4h14.7c.8 0 1.4-.6 1.4-1.4V3c0-.8-.6-1.4-1.4-1.4H4.1c-.8 0-1.4.6-1.4 1.4v1c0 .3-.3.6-.6.6s-.5-.4-.5-.7V3C1.6 1.6 2.7.5 4.1.5h14.7c1.4 0 2.5 1.1 2.5 2.5v18.9c-.1 1.3-1.2 2.4-2.6 2.4z"
        />
        <path
          d="M18.7 24.8H4.1c-1.6 0-3-1.3-3-3v-1c0-.6.5-1 1-1s1 .5 1 1v1c0 .5.4.9.9.9h14.7c.5 0 .9-.4.9-.9V3c0-.5-.4-.9-.9-.9H4.1c-.5-.1-1 .3-1 .9v1c0 .6-.5 1-1 1s-1-.5-1-1V3c0-1.6 1.3-3 3-3h14.7c1.6 0 3 1.3 3 3v18.9c-.1 1.5-1.5 2.9-3.1 2.9zM2.1 20.7c-.1 0-.1.1-.1.1v1c0 1.1.9 2 2 2h14.7c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2H4.1c-1.1 0-2 .9-2 2v1c0 .1.2.1.2 0V3c0-1 .8-1.8 1.8-1.8h14.7c1 0 1.8.8 1.8 1.8v18.9c0 1-.8 1.8-1.8 1.8H4.1c-1 0-1.8-.8-1.8-1.8v-1c-.1-.1-.1-.2-.2-.2z"
        />
        <circle cx="11.7" cy="9.5" r="2.7" />
        <path
          d="M11.7 12.6c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.3 3.1-3.1 3.1zm0-5.3c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2 2.2-1 2.2-2.2-.9-2.2-2.2-2.2z"
        />
        <path
          d="M11.7 12.7c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3S15 7.7 15 9.5s-1.5 3.2-3.3 3.2zm0-5.3c-1.2 0-2.1 1-2.1 2.1s1 2.1 2.1 2.1 2.1-1 2.1-2.1-.9-2.1-2.1-2.1z"
        />
        <path
          d="M14.2 18c.7 0 1.3-.7 1.1-1.5-.5-2.4-1.9-4.2-3.6-4.2s-3.1 1.9-3.6 4.2c-.1.8.4 1.5 1.2 1.5h4.9z"
        />
        <path
          d="M14.2 18.4H9.3c-.5 0-.9-.2-1.2-.6-.3-.4-.4-.9-.3-1.5.5-2.7 2.2-4.6 4-4.6 1.8 0 3.5 1.9 4 4.6.1.6 0 1.1-.3 1.5-.4.4-.9.6-1.3.6zm-2.5-5.8c-1.4 0-2.7 1.6-3.1 3.9-.1.3 0 .6.2.7.1.2.3.2.5.2h4.9c.2 0 .4-.1.5-.2.2-.2.2-.5.2-.7-.5-2.2-1.8-3.9-3.2-3.9z"
        />
        <g>
          <path
            d="M14.2 18.5H9.3c-.5 0-1-.2-1.3-.6-.3-.4-.5-1-.4-1.6.5-2.8 2.2-4.7 4.1-4.7s3.6 1.9 4.1 4.7c.1.6 0 1.2-.4 1.6-.2.4-.7.6-1.2.6zm-2.5-5.8c-1.3 0-2.6 1.6-3 3.8-.1.3 0 .5.1.7.1.1.3.2.4.2h4.9c.2 0 .3-.1.4-.2.1-.2.2-.4.1-.7-.3-2.2-1.6-3.8-2.9-3.8z"
          />
        </g>
        <g>
          <path
            d="M3.4 7.9H1c-.3 0-.6-.3-.6-.6s.3-.5.6-.5h2.4c.3 0 .6.2.6.5s-.3.6-.6.6z"
          />
          <path
            d="M3.4 8H1c-.5 0-1-.4-1-.8s.5-.8 1-.8h2.4c.6 0 1 .4 1 .8S4 8 3.4 8zM1 7.1c-.1 0-.1 0-.1.1s.1.1.1.1h2.4c.1 0 .1 0 .1-.1s0-.1-.1-.1H1z"
          />
        </g>
        <g>
          <path
            d="M3.4 18.3H1c-.3 0-.6-.3-.6-.6s.3-.6.6-.6h2.4c.3 0 .6.3.6.6s-.3.6-.6.6z"
          />
          <path
            d="M3.4 18.4H1c-.6 0-1-.4-1-.8s.5-.8 1-.8h2.4c.6 0 1 .4 1 .8s-.4.8-1 .8zm-2.4-1c-.1 0-.1 0-.1.1s.1.1.1.1h2.4c.1 0 .1 0 .1-.1s-.1-.1-.1-.1H1z"
          />
        </g>
      </g>
    </switch>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: 18
    }
  }
};
</script>
